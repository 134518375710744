<template>
  <v-container fluid>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Home',
  data: () => ({
    loading: true
  }),
  computed: mapState({
  }),
  created () {
  }
}
</script>
