<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title dense>
            <v-btn @click="newDialog=true" color="primary" v-if="can('CAN_CREATE_CAMPAIGN')">Create Campaign</v-btn>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table :headers="headers" :items="records" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer :loading="loading">
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatdate }}
              </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" md="3" class="text-right pt-6 caption"><span> {{ totalItemsInPage }} </span> out of <span class="font-weight-bold">{{ totalElements }} </span> Records </v-col>
            <v-col cols="12" md="5" class="text-left"> <v-pagination  v-model="page" :length="pageCount"> </v-pagination> </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="newDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Campaign</span>
        </v-card-title>
        <v-card-text>
         <v-form v-model="valid" ref="createform" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field v-model="record.name" label="Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-file-input chips v-model="record.contactsfile" label="Contacts File"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="10" md="10">
                <v-textarea name="input-7-1" label="Message" v-model="record.message"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-checkbox v-model="hasMediaCheckBox" label="Has Media?"></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="hasMediaCheckBox">
              <v-col cols="12" sm="5" md="5">
                <v-select :items="mediaTypes" label="Media Type" v-model="record.mediaType"></v-select>
              </v-col>
            </v-row>
            <v-row v-if="hasMediaCheckBox">
              <v-col cols="12" sm="5" md="5">
                <v-file-input chips v-model="record.mediaFile" label="Media File"></v-file-input>
              </v-col>
            </v-row>
          </v-container>
         </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="newDialog=false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="triggerCreateRecord" :loading="loading"> Create </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Campaigns',
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'No of Contacts', value: 'numberOfContacts' },
      { text: 'Message', value: 'message' },
      { text: 'Media URL', value: 'mediaUrl' },
      { text: 'Date Created', value: 'createdAt' }
    ],
    loading: false,
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    payload: { perPage: 10, page: 1 },
    records: [],
    totalElements: 0,
    totalItemsInPage: 0,
    newDialog: false,
    valid: true,
    record: {},
    hasMediaCheckBox: false,
    mediaTypes: ['IMAGE', 'VIDEO']
  }),
  created () {
    this.triggerGetRecords()
    this.debouncedGetAnswer = _.debounce(this.triggerGetRecords, 1000)
  },
  watch: {
    page () {
      this.payload.page = this.page
      this.triggerGetRecords()
    }
  },
  methods: {
    ...mapActions({ createRecord: 'campaign/createRecord', getRecords: 'campaign/getRecords' }),
    triggerCreateRecord () {
      if (this.$refs.createform.validate()) {
        this.loading = true
        const formData = new FormData()
        formData.append('name', this.record.name)
        formData.append('message', this.record.message)
        formData.append('contactsFile', this.record.contactsfile)
        if (this.hasMediaCheckBox) {
          formData.append('mediaFile', this.record.mediaFile)
          formData.append('mediaType', this.record.mediaType)
        }
        this.createRecord(formData).then(response => {
          this.loading = false
          this.newDialog = false
          this.record = {}
          this.triggerGetRecords()
          this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
        }).catch(error => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        })
      }
    },
    triggerGetRecords () {
      this.loading = true
      this.records = []
      this.payload.search = this.search
      this.getRecords(this.payload).then(response => {
        this.records = response.data.data
        this.pageCount = response.data.totalPages
        this.totalElements = response.data.totalElements
        this.totalItemsInPage = response.data.totalItemsInPage
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    }
  }
}
</script>
