<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title dense>
            <v-btn @click="newDialog=true" color="primary" v-if="can('CAN_CREATE_COMPANY')">Create Company</v-btn>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table :headers="headers" :items="records" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer :loading="loading">
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatdate }}
              </template>
              <template v-slot:[`item.actions`]="{ item }" v-if="can('CAN_UPDATE_COMPANY')">
              <v-icon small class="mr-2" @click="showEditDialog(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" md="3" class="text-right pt-6 caption"><span> {{ totalItemsInPage }} </span> out of <span class="font-weight-bold">{{ totalElements }} </span> Records </v-col>
            <v-col cols="12" md="5" class="text-left"> <v-pagination  v-model="page" :length="pageCount"> </v-pagination> </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="newDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Company</span>
        </v-card-title>
        <v-card-text>
         <v-form v-model="valid" ref="createform" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field v-model="record.name" label="Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field v-model="record.units" label="Initial Units"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
         </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="newDialog=false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="triggerCreateRecord" :loading="loading"> Create </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Company</span>
        </v-card-title>
        <v-card-text>
         <v-form v-model="valid" ref="updateform" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field v-model="record.name" label="Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field v-model="record.units" label="Units"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
         </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="editDialog=false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="triggerUpdateRecord" :loading="loading"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Campaigns',
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Units', value: 'units' },
      { text: 'Date Created', value: 'createdAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    loading: false,
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    payload: { perPage: 10, page: 1 },
    records: [],
    totalElements: 0,
    totalItemsInPage: 0,
    newDialog: false,
    editDialog: false,
    valid: true,
    record: {}
  }),
  created () {
    this.triggerGetRecords()
    this.debouncedGetAnswer = _.debounce(this.triggerGetRecords, 1000)
  },
  watch: {
    page () {
      this.payload.page = this.page
      this.triggerGetRecords()
    }
  },
  methods: {
    ...mapActions({ createRecord: 'company/createRecord', updateRecord: 'company/updateRecord', getRecords: 'company/getRecords' }),
    triggerCreateRecord () {
      if (this.$refs.createform.validate()) {
        this.loading = true
        this.createRecord(this.record).then(response => {
          this.loading = false
          this.newDialog = false
          this.record = {}
          this.triggerGetRecords()
          this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
        }).catch(error => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        })
      }
    },
    triggerUpdateRecord () {
      if (this.$refs.updateform.validate()) {
        this.loading = true
        this.updateRecord(this.record).then(response => {
          this.loading = false
          this.editDialog = false
          this.record = {}
          this.triggerGetRecords()
          this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
        }).catch(error => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        })
      }
    },
    triggerGetRecords () {
      this.loading = true
      this.records = []
      this.payload.search = this.search
      this.getRecords(this.payload).then(response => {
        this.records = response.data.data
        this.pageCount = response.data.totalPages
        this.totalElements = response.data.totalElements
        this.totalItemsInPage = response.data.totalItemsInPage
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    },
    showEditDialog (item) {
      this.record = item
      this.editDialog = true
    }
  }
}
</script>
